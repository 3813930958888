import {
  useColorModeValue,
  chakra,
  Button,
  Skeleton,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

import IconSvg from 'ui/shared/IconSvg';

interface Props {
  isOpen?: boolean;
  isLoading?: boolean;
  className?: string;
  onClick?: () => void;
}

const AdditionalInfoButton = (
  { isOpen, onClick: handleClick, className, isLoading }: Props,
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  const handleOpen = () => {};

  const handleClose = () => {};

  if (isLoading) {
    return <Skeleton boxSize={6} borderRadius="sm" flexShrink={0} />;
  }

  return (
    <Button
      variant="unstyled"
      display="inline-flex"
      alignItems="center"
      className={className}
      ref={ref}
      userSelect="none"
      borderRadius="4px"
      w="24px"
      h="24px"
      flexShrink={0}
      onClick={handleClick}
    >
      <IconSvg name="info" color="#8F959E" boxSize={4} />
    </Button>
  );
};

export default chakra(React.forwardRef(AdditionalInfoButton));
