import {
  Modal,
  ModalContent,
  ModalCloseButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';

import type { Transaction } from 'types/api/transaction';

import AdditionalInfoButton from 'ui/shared/AdditionalInfoButton';

import TxAdditionalInfoContainer from './TxAdditionalInfoContainer';
import TxAdditionalInfoContent from './TxAdditionalInfoContent';

type Props = (
  | {
      hash: string;
      tx?: undefined;
    }
  | {
      hash?: undefined;
      tx: Transaction;
    }
) & {
  isMobile?: boolean;
  isLoading?: boolean;
};

const TxAdditionalInfo = ({ hash, tx, isMobile, isLoading }: Props) => {
  const bgColor = useColorModeValue('#FFFFFF', '#282828');
  const { isOpen, onOpen: handleOpen, onClose: handleClose } = useDisclosure();

  const content =
    hash !== undefined ? (
      <TxAdditionalInfoContainer hash={hash} />
    ) : (
      <TxAdditionalInfoContent tx={tx} />
    );

  if (isMobile) {
    return (
      <>
        <AdditionalInfoButton onClick={handleOpen} isLoading={isLoading} />
        <Modal isOpen={isOpen} onClose={handleClose} size="full">
          <ModalContent paddingTop={4} backgroundColor={bgColor}>
            <ModalCloseButton />
            {content}
          </ModalContent>
        </Modal>
      </>
    );
  }
  return (
    <Popover placement="right-start" openDelay={300} isLazy>
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            <AdditionalInfoButton isOpen={isOpen} isLoading={isLoading} />
          </PopoverTrigger>
          <PopoverContent borderRadius="8px" backgroundColor={bgColor}>
            <PopoverBody>{content}</PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default React.memo(TxAdditionalInfo);
