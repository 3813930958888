import {
  Box,
  Flex,
  chakra,
  useColorModeValue,
  Skeleton,
} from '@chakra-ui/react';
import clamp from 'lodash/clamp';
import React from 'react';

interface Props {
  className?: string;
  value: number;
  colorScheme?: 'green' | 'white';
  isLoading?: boolean;
}

const WIDTH = 60;

const Utilization = ({
  className,
  value,
  colorScheme = 'green',
  isLoading,
}: Props) => {
  const valueString = `${clamp(value * 100 || 0, 0, 100).toLocaleString(undefined, { maximumFractionDigits: 2 })}%`;
  const color = useColorModeValue(
    colorScheme === 'white' ? '#1a1a1a' : '#03AB46',
    colorScheme === 'white' ? '#ffffff' : '#0F6'
  );
  const valueColor = useColorModeValue(
    colorScheme === 'white' ? '#1A1A1A99' : '#03AB46',
    colorScheme === 'white' ? '#eeeeee66' : '#0F6'
  );

  return (
    <Flex className={className} alignItems="center" columnGap={2}>
      <Skeleton
        isLoaded={!isLoading}
        w={`${WIDTH}px`}
        h="3px"
        borderRadius="full"
        overflow="hidden"
      >
        <Box
          bg={useColorModeValue('blackAlpha.200', 'whiteAlpha.200')}
          h="100%"
        >
          <Box bg={color} w={valueString} h="100%" />
        </Box>
      </Skeleton>
      <Skeleton isLoaded={!isLoading} color={valueColor} fontWeight="bold">
        <span>{valueString}</span>
      </Skeleton>
    </Flex>
  );
};

export default React.memo(chakra(Utilization));
