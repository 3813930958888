import { TagLabel, Tooltip, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import type { IconName } from 'ui/shared/IconSvg';
import IconSvg from 'ui/shared/IconSvg';
import Tag from 'ui/shared/chakra/Tag';

export type StatusTagType = 'ok' | 'error' | 'pending';

export interface Props {
  type: 'ok' | 'error' | 'pending';
  text: string;
  errorText?: string | null;
  isLoading?: boolean;
}

const StatusTag = ({ type, text, errorText, isLoading }: Props) => {
  let icon: IconName;
  let color;
  const positiveColor = useColorModeValue('#03AB46', '#0F6');
  const negativeColor = useColorModeValue('#FD4E4E', '#FD4E4E');
  const pendingColor = useColorModeValue('#1A1A1ACC', '#EEEEEE');

  switch (type) {
    case 'ok':
      icon = 'status/success';
      color = positiveColor;
      break;
    case 'error':
      icon = 'status/error';
      color = negativeColor;
      break;
    case 'pending':
      icon = 'status/pending';
      // FIXME: it's not gray on mockups
      // need to implement new color scheme or redefine colors here
      color = pendingColor;
      break;
  }

  return (
    <Tooltip label={errorText}>
      <Tag p={1} color={color} display="inline-flex" isLoading={isLoading}>
        <IconSvg boxSize={3} color={color} name={icon} mr="4px" />
        <TagLabel lineHeight="16px">{text}</TagLabel>
      </Tag>
    </Tooltip>
  );
};

export default StatusTag;
