import {
  Alert,
  Link,
  Text,
  chakra,
  useTheme,
  useColorModeValue,
  Skeleton,
  Tr,
  Td,
} from '@chakra-ui/react';
import React from 'react';

interface InjectedProps {
  content: React.ReactNode;
}

interface Props {
  type?: 'transaction' | 'token_transfer' | 'deposit' | 'block';
  children?: (props: InjectedProps) => JSX.Element;
  className?: string;
  url: string;
  alert?: string;
  num?: number;
  isLoading?: boolean;
}

const SocketNewItemsNotice = chakra(
  ({
    children,
    className,
    url,
    num,
    alert,
    type = 'transaction',
    isLoading,
  }: Props) => {
    const noticeBgColor = useColorModeValue('#9E9E9E1F', '#FFFFFF33');
    const color = useColorModeValue('#1a1a1a', '#ffffff');

    const theme = useTheme();

    const alertContent = (() => {
      if (alert) {
        return alert;
      }

      let name;

      switch (type) {
        case 'token_transfer':
          name = 'token transfer';
          break;
        case 'deposit':
          name = 'deposit';
          break;
        case 'block':
          name = 'block';
          break;
        default:
          name = 'transaction';
          break;
      }

      if (!num) {
        return `scanning new ${name}s...`;
      }

      return (
        <>
          <Link href={url} color="#749BFF">
            {num.toLocaleString()} more {name}
            {num > 1 ? 's' : ''}
          </Link>
          <Text whiteSpace="pre" color={color}>
            {' '}
            ha{num > 1 ? 've' : 's'} come in
          </Text>
        </>
      );
    })();

    const content = !isLoading ? (
      <Alert
        borderRadius="4px"
        className={className}
        status="warning"
        px={4}
        py="6px"
        fontWeight={400}
        fontSize="sm"
        lineHeight={5}
        bgColor={noticeBgColor}
        color={color}
      >
        {alertContent}
      </Alert>
    ) : (
      <Skeleton className={className} h="33px" />
    );

    return children ? children({ content }) : content;
  }
);

export default SocketNewItemsNotice;

export const Desktop = ({ ...props }: Props) => (
  <SocketNewItemsNotice
    borderRadius="0 0 4px 4px"
    h={props.isLoading ? 5 : 'auto'}
    maxW={props.isLoading ? '215px' : undefined}
    w="100%"
    mx={props.isLoading ? 4 : 0}
    my={props.isLoading ? '6px' : 0}
    {...props}
  >
    {({ content }) => (
      <Tr>
        <Td colSpan={100} p={0}>
          {content}
        </Td>
      </Tr>
    )}
  </SocketNewItemsNotice>
);

export const Mobile = ({ ...props }: Props) => (
  <SocketNewItemsNotice borderBottomRadius={0} {...props} />
);
