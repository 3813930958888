import BigNumber from 'bignumber.js';

import { GWEI, WEI } from 'lib/consts';
import type { Unit } from 'types/unit';

export default function getValueWithUnit(value: string | number, unit: Unit = 'wei') {
  let unitBn: BigNumber.Value;
  switch (unit) {
    case 'wei':
      unitBn = WEI;
      break;
    case 'gwei':
      unitBn = GWEI;
      break;
    default:
      unitBn = new BigNumber(1);
  }

  const valueBn = new BigNumber(value);
  return valueBn.dividedBy(unitBn);
}
