import {
  Skeleton,
  Tag as ChakraTag,
  useColorModeValue,
} from '@chakra-ui/react';
import type { TagProps } from '@chakra-ui/react';
import React from 'react';

import TruncatedTextTooltip from 'ui/shared/TruncatedTextTooltip';

interface Props extends TagProps {
  isLoading?: boolean;
  color?: string;
  type?: string;
}

const Tag = (
  { isLoading, color, type, ...props }: Props,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const bgColor = useColorModeValue(
    type === 'method' ? '#1A1A1A14' : '#1A1A1A0A',
    '#282828'
  );
  const tagColor = useColorModeValue('#1A1A1A', '#FFFFFF');
  const lineHeight = type === 'method' ? '18px' : 'unset';
  if (props.isTruncated && typeof props.children === 'string') {
    if (!props.children) {
      return null;
    }

    return (
      <Skeleton
        isLoaded={!isLoading}
        display="inline-block"
        borderRadius="4px"
        maxW="100%"
      >
        <TruncatedTextTooltip label={props.children}>
          <ChakraTag
            p={1}
            lineHeight={lineHeight}
            backgroundColor={bgColor}
            color={tagColor}
            {...props}
            ref={ref}
          />
        </TruncatedTextTooltip>
      </Skeleton>
    );
  }
  return (
    <Skeleton
      isLoaded={!isLoading}
      display="inline-block"
      borderRadius="4px"
      maxW="100%"
    >
      <ChakraTag
        p={1}
        lineHeight={lineHeight}
        backgroundColor={bgColor}
        color={color || tagColor}
        {...props}
        ref={ref}
      />
    </Skeleton>
  );
};

export default React.memo(React.forwardRef(Tag));
